import { Preferences } from 'components/pages/Home/types/Preferences';
import {
  LeaseFormType,
  LeaseSpaceLogValues,
} from 'components/pages/Leases/LeaseForm/form/LeaseForm';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { TBasePaths } from 'constants/translations';
import { LeaseEntity } from 'entities/Lease/Lease';
import { LeaseSpaceEntity } from 'entities/Lease/LeaseSpace';
import { LeaseSpaceStatusEnum } from 'enums/PrismaEnums';
import { t } from 'i18next';
import moment from 'moment';
import { SetterOrUpdater } from 'recoil';
import { UpdateLeaseSpaceDto } from 'types/lease-space';
import { IPropertyItem, updatePropertyIfChanged } from 'utils/activityStreamHelper';
import logger from 'utils/logger';

import { notEmpty } from './filters';
import { convertCurrency } from './unitConverters';

/**
 * Handles errors gracefully and logs them before returning a fallback value.
 * @param error The caught error or unknown type
 * @param fallbackValue The value to return in case of error
 * @param initiator The value that represents the function name to track
 * @returns The fallback value provided
 */
const handleCatchAndLog = <T>(error: unknown, fallbackValue: T, initiator: string): T => {
  const err = error as Error;
  logger(err, initiator);
  Snackbar.warning(err.message);
  return fallbackValue;
};

export const convertAreaIfNeeded = (
  rentArea: number,
  rentAreaUom: string,
  desiredUom: string
): number => {
  if (rentAreaUom !== desiredUom) {
    return rentAreaUom === 'sqm' ? rentArea * 10.76391042 : rentArea / 10.76391042;
  }
  return rentArea;
};

export const filterActiveLeaseSpaces = (leaseSpaces: LeaseSpaceEntity[]): LeaseSpaceEntity[] => {
  return leaseSpaces.filter((ls) => ls.leaseSpaceStatus === LeaseSpaceStatusEnum.active);
};

export const calculateTotalRentAreaByLease = (lease: LeaseEntity, uom: string): number => {
  try {
    let total = 0;
    const currentDate = moment.utc();
    filterActiveLeaseSpaces(lease.leaseSpace).forEach((ls) => {
      const rentArea = Number(ls.rentArea);
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;
      if (
        rentArea &&
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        const rentAreaConverted = convertAreaIfNeeded(rentArea, lease.unitMeas, uom);
        total += rentAreaConverted;
      }
    });

    return total;
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalRentAreaByLease');
  }
};

export const calculateTotalUsableAreaByLease = (leaseSpaces: LeaseSpaceEntity[]): number => {
  try {
    const currentDate = moment.utc();
    return filterActiveLeaseSpaces(leaseSpaces).reduce((accumulator: number, ls) => {
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;

      if (
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        return accumulator + Number(ls.usableArea);
      }

      return accumulator;
    }, 0);
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalUsableAreaByLease');
  }
};

export const calculateTotalUnitsByLease = (leaseSpaces: LeaseSpaceEntity[]): number => {
  try {
    const currentDate = moment.utc();
    return filterActiveLeaseSpaces(leaseSpaces).reduce((accumulator: number, ls) => {
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;

      if (
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        return accumulator + Number(ls.unitCnt);
      }

      return accumulator;
    }, 0);
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalUnitsByLease');
  }
};

export const calculateAnnualCostPerAreaWithoutTax = (
  lease: LeaseEntity,
  preferences: Preferences
): number | undefined => {
  if (!lease.costSummary) return;

  const totalRentArea = calculateTotalRentAreaByLease(lease, preferences.uom);
  return totalRentArea && totalRentArea != 0
    ? convertCurrency(
        preferences,
        lease.costSummary?.annualCostWithoutTax / totalRentArea,
        lease.currencyCode
      )
    : undefined;
};

export const concatTotalFloorSuitSpaces = (leaseSpaces: LeaseSpaceEntity[]): string => {
  try {
    const filtered = filterActiveLeaseSpaces(leaseSpaces)
      .map((ls) => ls.floorSuiteSpace)
      .filter(notEmpty);
    filtered.sort((a, b) => a.localeCompare(b));
    return filtered.join('; ');
  } catch (e) {
    return handleCatchAndLog(e, '0', 'concatTotalFloorSuitSpaces');
  }
};

export const hasMultipleLeaseSpaces = (leaseSpaces: LeaseSpaceEntity[]): boolean => {
  try {
    return leaseSpaces.length > 1;
  } catch (e) {
    return handleCatchAndLog(e, false, 'hasMultipleLeaseSpaces');
  }
};

export const findRelatedLeaseSpace = (
  leaseSpaces: LeaseSpaceEntity[],
  lease: LeaseEntity | null
): LeaseSpaceEntity | undefined | null => {
  try {
    return leaseSpaces.find((s) => s.leaseSpaceUuid === lease?.leaseUuid) ?? leaseSpaces.length
      ? leaseSpaces[0]
      : null;
  } catch (e) {
    return handleCatchAndLog(e, undefined, 'findRelatedLeaseSpace');
  }
};

const shouldUpdateSpace = (
  unitCnt: number,
  rentArea: number,
  usableArea: number,
  floorSuiteSpace: string,
  planExitDate: string,
  leaseSpaceNote: string,
  leaseSpaceStatus: string,
  leaseAreaTypeUuid: string,
  commenceDate: string,
  expireDate: string,
  expireReviewDate: string,
  executionDate: string,
  possessionDate: string,
  openingDate: string,
  closingDate: string
): {
  isUnitCnt: boolean;
  isRentArea: boolean;
  isUsableArea: boolean;
  isFloorSuiteSpace: boolean;
  shouldSpaceBeUpdated: boolean;
  isPlanExitDate: boolean;
  isLeaseSpaceNote: boolean;
  isLeaseSpaceStatus: boolean;
  isLeaseAreaTypeUuid: boolean;
  isCommenceDate: boolean;
  isExpireDate: boolean;
  isExpireReviewDate: boolean;
  isExecutionDate: boolean;
  isPossessionDate: boolean;
  isOpeningDate: boolean;
  isClosingDate: boolean;
} => {
  const isUnitCnt = Boolean(unitCnt || unitCnt === 0);
  const isRentArea = Boolean(rentArea || rentArea === 0);
  const isUsableArea = Boolean(usableArea || usableArea === 0);
  const isFloorSuiteSpace = Boolean(floorSuiteSpace || floorSuiteSpace === '');
  const isPlanExitDate = Boolean(planExitDate || planExitDate === '' || planExitDate === null);
  const isLeaseSpaceNote = Boolean(leaseSpaceNote || leaseSpaceNote === '');
  const isLeaseSpaceStatus = Boolean(leaseSpaceStatus || leaseSpaceStatus === '');
  const isLeaseAreaTypeUuid = Boolean(leaseAreaTypeUuid || leaseAreaTypeUuid === '');
  const isCommenceDate = Boolean(commenceDate || commenceDate === '' || commenceDate === null);
  const isExpireDate = Boolean(expireDate || expireDate === '' || expireDate === null);
  const isExpireReviewDate = Boolean(
    expireReviewDate || expireReviewDate === '' || expireReviewDate === null
  );
  const isExecutionDate = Boolean(executionDate || executionDate === '' || executionDate === null);
  const isPossessionDate = Boolean(
    possessionDate || possessionDate === '' || possessionDate === null
  );
  const isOpeningDate = Boolean(openingDate || openingDate === '' || openingDate === null);
  const isClosingDate = Boolean(closingDate || closingDate === '' || closingDate === null);

  return {
    isUnitCnt,
    isRentArea,
    isUsableArea,
    isFloorSuiteSpace,
    isPlanExitDate,
    isLeaseSpaceNote,
    isLeaseSpaceStatus,
    isLeaseAreaTypeUuid,
    isCommenceDate,
    isExpireDate,
    isExpireReviewDate,
    isExecutionDate,
    isPossessionDate,
    isOpeningDate,
    isClosingDate,
    shouldSpaceBeUpdated:
      isUnitCnt ||
      isRentArea ||
      isUsableArea ||
      isFloorSuiteSpace ||
      isPlanExitDate ||
      isLeaseSpaceNote ||
      isLeaseSpaceStatus ||
      isLeaseAreaTypeUuid ||
      isCommenceDate ||
      isExpireDate ||
      isExpireReviewDate ||
      isExecutionDate ||
      isPossessionDate ||
      isOpeningDate ||
      isClosingDate,
  };
};

export const updateLeaseSpaceIfNeeded = async (
  updatedValues: Record<string, any>,
  leaseSpaces: LeaseSpaceEntity[],
  lease: LeaseEntity | null,
  onUpdateLeaseSpace: (dto: UpdateLeaseSpaceDto) => Promise<LeaseSpaceEntity>,
  onSetLeaseSpacesState: SetterOrUpdater<LeaseSpaceEntity[]>
) => {
  const relatedLeaseSpace = findRelatedLeaseSpace(leaseSpaces, lease);
  const leaseSpaceUuid = relatedLeaseSpace?.leaseSpaceUuid;
  if (!leaseSpaceUuid) return Snackbar.warning(t(`${TBasePaths.PA_COMMON_WORD}.uuidNotProvided`));

  const {
    unitCnt,
    rentArea,
    usableArea,
    floorSuiteSpace,
    planExitDate,
    leaseSpaceNote,
    leaseSpaceStatus,
    leaseAreaTypeUuid,
    commenceDate,
    expireDate,
    expireReviewDate,
    executionDate,
    possessionDate,
    openingDate,
    closingDate,
  } = updatedValues;
  const {
    isUnitCnt,
    isRentArea,
    isUsableArea,
    isFloorSuiteSpace,
    isPlanExitDate,
    isLeaseSpaceNote,
    isLeaseSpaceStatus,
    isLeaseAreaTypeUuid,
    isCommenceDate,
    isExpireDate,
    isExpireReviewDate,
    isExecutionDate,
    isPossessionDate,
    isOpeningDate,
    isClosingDate,
    shouldSpaceBeUpdated,
  } = shouldUpdateSpace(
    unitCnt,
    rentArea,
    usableArea,
    floorSuiteSpace,
    planExitDate,
    leaseSpaceNote,
    leaseSpaceStatus,
    leaseAreaTypeUuid,
    commenceDate,
    expireDate,
    expireReviewDate,
    executionDate,
    possessionDate,
    openingDate,
    closingDate
  );

  const ifChangedIsMonthToMonth = relatedLeaseSpace.isMonthToMonth !== updatedValues.isMonthToMonth;

  if (shouldSpaceBeUpdated || ifChangedIsMonthToMonth) {
    const updatedLeaseSpace: UpdateLeaseSpaceDto = {
      leaseSpaceUuid,
      ...(isUnitCnt && { unitCnt }),
      ...(isRentArea && { rentArea }),
      ...(isUsableArea && { usableArea }),
      ...(isFloorSuiteSpace && { floorSuiteSpace }),
      ...(isPlanExitDate && { planExitDate }),
      ...(isLeaseSpaceNote && { leaseSpaceNote }),
      ...(isLeaseSpaceStatus && { leaseSpaceStatus }),
      ...(isLeaseAreaTypeUuid && { leaseAreaTypeUuid }),
      ...(isCommenceDate && { commenceDate }),
      ...(isExpireDate && { expireDate }),
      ...(isExpireReviewDate && { expireReviewDate }),
      ...(isExecutionDate && { executionDate }),
      ...(isPossessionDate && { possessionDate }),
      ...(isOpeningDate && { openingDate }),
      ...(isClosingDate && { closingDate }),
      ...(ifChangedIsMonthToMonth && { isMonthToMonth: updatedValues.isMonthToMonth }),
    };

    await onUpdateLeaseSpace(updatedLeaseSpace);

    onSetLeaseSpacesState((prev) =>
      prev.map((p) => (p.leaseSpaceUuid === leaseSpaceUuid ? { ...p, ...updatedLeaseSpace } : p))
    );
  }
};

export const generateLeaseSpaceLogValues = (
  dirtyValues: Record<string, any>,
  formValues: LeaseFormType,
  oldLeaseSpaceLogValues: LeaseSpaceLogValues | null
) => {
  const propertiesToUpdate: IPropertyItem[] = [
    {
      propertyName: 'unitCnt',
      newValue: formValues.unitCnt,
      oldValue: oldLeaseSpaceLogValues?.unitCnt,
      updateKey: 'oldUnitCnt',
    },
    {
      propertyName: 'rentArea',
      newValue: formValues.rentArea,
      oldValue: oldLeaseSpaceLogValues?.rentArea,
      updateKey: 'oldRentArea',
    },
    {
      propertyName: 'usableArea',
      newValue: formValues.usableArea,
      oldValue: oldLeaseSpaceLogValues?.usableArea,
      updateKey: 'oldUsableArea',
    },
    {
      propertyName: 'floorSuiteSpace',
      newValue: formValues.floorSuiteSpace,
      oldValue: oldLeaseSpaceLogValues?.floorSuiteSpace,
      updateKey: null,
    },
    {
      propertyName: 'planExitDate',
      newValue: formValues.planExitDate,
      oldValue: oldLeaseSpaceLogValues?.planExitDate,
      updateKey: 'oldPlanExitDate',
    },
    {
      propertyName: 'leaseSpaceNote',
      newValue: formValues.leaseSpaceNote,
      oldValue: oldLeaseSpaceLogValues?.leaseSpaceNote,
      updateKey: 'oldLeaseSpaceNote',
    },
    {
      propertyName: 'leaseSpaceStatus',
      newValue: formValues.leaseSpaceStatus,
      oldValue: oldLeaseSpaceLogValues?.leaseSpaceStatus,
      updateKey: 'oldLeaseSpaceStatus',
    },
    {
      propertyName: 'leaseAreaTypeUuid',
      newValue: formValues.leaseAreaTypeUuid,
      oldValue: oldLeaseSpaceLogValues?.leaseAreaTypeUuid,
      updateKey: 'oldLeaseAreaTypeUuid',
    },
    {
      propertyName: 'expireDate',
      newValue: formValues.expireDate,
      oldValue: oldLeaseSpaceLogValues?.expireDate,
      updateKey: 'oldExpireDate',
    },
    {
      propertyName: 'expireReviewDate',
      newValue: formValues.expireReviewDate,
      oldValue: oldLeaseSpaceLogValues?.expireReviewDate,
      updateKey: 'oldExpireReviewDate',
    },
    {
      propertyName: 'commenceDate',
      newValue: formValues.commenceDate,
      oldValue: oldLeaseSpaceLogValues?.commenceDate,
      updateKey: 'oldCommenceDate',
    },
    {
      propertyName: 'executionDate',
      newValue: formValues.executionDate,
      oldValue: oldLeaseSpaceLogValues?.executionDate,
      updateKey: 'oldExecutionDate',
    },
    {
      propertyName: 'possessionDate',
      newValue: formValues.possessionDate,
      oldValue: oldLeaseSpaceLogValues?.possessionDate,
      updateKey: 'oldPossessionDate',
    },
    {
      propertyName: 'openingDate',
      newValue: formValues.openingDate,
      oldValue: oldLeaseSpaceLogValues?.openingDate,
      updateKey: 'oldOpeningDate',
    },
    {
      propertyName: 'closingDate',
      newValue: formValues.closingDate,
      oldValue: oldLeaseSpaceLogValues?.closingDate,
      updateKey: 'oldClosingDate',
    },
    {
      propertyName: 'isMonthToMonth',
      newValue: formValues.isMonthToMonth,
      oldValue: oldLeaseSpaceLogValues?.isMonthToMonth,
      updateKey: 'oldIsMonthToMonth',
    },
  ];
  return propertiesToUpdate.reduce((acc, item) => {
    return updatePropertyIfChanged(acc, item);
  }, dirtyValues);
};
